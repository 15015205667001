import React from 'react'
import mdaq_logo from '../../assets/logo.svg'
import mdaq_short from '../../assets/logo-short.svg'
import mdaq_dark_pro from '../../assets/logo-short-dark.svg'
import mdaq_dark_logo from '../../assets/logo-dark.svg'
import { Layout, Menu } from 'antd'
import { MoreOutlined } from '@ant-design/icons'
import { CollapseType } from 'antd/es/layout/Sider'
import { DARK } from '../../constant/theme'

const { Sider } = Layout

const GSider = (props: { theme: string; collapsed: boolean; onCollapsed: (collapsed: boolean, type: CollapseType) => void }) => {
    const { theme, collapsed, onCollapsed, ...rest } = props
    return (
        <Sider collapsible collapsed={collapsed} collapsedWidth='50' onCollapse={onCollapsed}>
            {theme === DARK ? (
                <img
                    data-testid='main-logo-id'
                    src={collapsed ? mdaq_dark_pro : mdaq_dark_logo}
                    className='mdaq-logo-style'
                    alt={collapsed ? 'mdaq_short' : 'mdaq_dark_logo'}
                />
            ) : (
                <img
                    data-testid='main-logo-id'
                    src={collapsed ? mdaq_short : mdaq_logo}
                    className='mdaq-logo-style'
                    alt={collapsed ? 'mdaq_short' : 'mdaq_logo'}
                />
            )}
            <Menu className='myfx-menu' theme='dark' mode='inline' {...rest} />
            <MoreOutlined className='trigger' data-testid='collapse-btn-id' onClick={() => onCollapsed(collapsed, 'clickTrigger')} />
        </Sider>
    )
}

export default GSider
